import { useMobile } from 'common-nextjs';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { Checkbox, Input } from 'ui';
import { pluralize, qsUpdateString } from 'utils';
import LockerSelectItems from '~/components/NewLockers/BulkActions/LockerSelectItems';
import LockerTradeInExportButton from '~/components/NewLockers/LockerTradeInExportButton';
import SeeAllFilters from '~/components/NewLockers/SeeAllFilters';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import SearchIcon from '~/public/static/svg/SearchIcon';
import { RailsFacetResponse } from '~/typings/services/rails/responses';
import { LockerRouteQuery } from '../../pages/locker/[username]/[itemState]';

interface Props {
  facetResponse?: RailsFacetResponse;
  isSelectingAll?: boolean;
  onToggleSelectAll?: () => void;
}

const LockerSearch: React.FC<Props> = ({
  facetResponse,
  isSelectingAll,
  onToggleSelectAll,
}) => {
  const router = useRouter();
  const myLocker = useIsMyLocker();
  const query = router.query as LockerRouteQuery;
  const [search, setSearch] = useState(query.q || '');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const hasChanged = useRef(false);
  const mobile = useMobile();

  useDebounce(
    () => {
      setDebouncedSearch(search);
      hasChanged.current = true;
    },
    300,
    [search],
  );

  function navigate(modifiedQuery: Record<string, unknown>) {
    const split = router.asPath?.split('?');
    const newQs = qsUpdateString([split[1], modifiedQuery]);
    router.push(router.route + newQs, split?.[0] + newQs);
  }

  useEffect(() => {
    if (hasChanged.current) {
      navigate({ q: debouncedSearch || null, page: null });
    }
  }, [debouncedSearch]);

  const input = (
    <div className="flex flex-1 justify-center space-x-4 md:space-x-2">
      <Input
        leftAdornment={<SearchIcon className="ml-3" fill="currentColor" />}
        className="h-8 max-w-sm"
        placeholder={`Search ${query.username}'s items`}
        value={search}
        onChange={e => setSearch(e.target.value)}
        type="search"
      />

      <SeeAllFilters />
    </div>
  );

  const itemsCount = (
    <>
      {facetResponse?.meta.paging.total_count}{' '}
      {pluralize(facetResponse?.meta.paging.total_count, 'Item', 'Items')}
    </>
  );

  const itemsText = query.select ? (
    <label className="flex items-center space-x-2 text-sm font-semibold">
      <Checkbox
        name="select_all"
        onChange={onToggleSelectAll}
        checked={isSelectingAll}
      />
      <span>Select {itemsCount}</span>
    </label>
  ) : (
    <div className="text-sm font-semibold">{itemsCount}</div>
  );

  // const selectItemsButton = <Button variant="text">Select Items</Button>;
  const selectItemsButton = <LockerSelectItems />;

  if (mobile) {
    return (
      <div className="mb-4">
        <div className="mb-2">{input}</div>

        <div className="flex items-center">
          {itemsText}
          <div>{selectItemsButton}</div>
          {myLocker && (
            <div>
              <LockerTradeInExportButton />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-4 flex items-center">
      {itemsText}
      {input}
      <div className="flex-1" />
      {myLocker && (
        <div>
          <LockerTradeInExportButton />
        </div>
      )}
      <div>{selectItemsButton}</div>
    </div>
  );
};

export default LockerSearch;
