import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { Button, Message } from 'ui';
import { postExportItems } from '~/api/locker';
import ErrorMessage from '~/components/Form/ErrorMessage';
import ManagedConfirmationModal from '~/components/NewModal/ManagedConfirmationModal';
import SimpleModal from '~/components/NewModal/SimpleModal';
import ManagedPaywall from '~/components/SidelinePro/Paywall/ManagedPaywall';
import useBrowserRouteQuery from '~/hooks/useBrowserRouteQuery';
import useHasRailsSetting from '~/hooks/useHasRailsSetting';
import { useIsSidelinePro } from '~/hooks/useIsSidelinePro';

export default function LockerTradeInExportButton() {
  const [open, setOpen] = useState(false);
  const query = useBrowserRouteQuery();
  const isTradeInAccount = useHasRailsSetting('trade_in_account');
  const pro = useIsSidelinePro();

  const {
    mutate: exportItems,
    isLoading,
    isSuccess,
    error,
  } = useMutation(() => postExportItems(query), {
    onMutate: () => {
      setOpen(true);
    },
  });

  return (
    <>
      {pro || isTradeInAccount ? (
        <ManagedConfirmationModal
          trigger={<Button>Export...</Button>}
          title="Export Items"
          description="Are you sure you want to export these items?"
          okText="Yes"
          handleOK={() => exportItems()}
        />
      ) : (
        <ManagedPaywall source="Locker export">
          <Button>Export...</Button>
        </ManagedPaywall>
      )}

      <SimpleModal
        open={open}
        onClose={() => setOpen(false)}
        title={isLoading ? 'Exporting...' : 'Export Items'}
      >
        <ErrorMessage errors={error} />

        {isSuccess && (
          <Message success>
            {isTradeInAccount
              ? `Success! Your file will be sent to #trade-in-exports in a few minutes.`
              : "Success! You'll receive an email with a link to your report shortly."}
          </Message>
        )}

        <Button variant="outlined" onClick={() => setOpen(false)} fluid>
          OK
        </Button>
      </SimpleModal>
    </>
  );
}
